<template>
    <div>
        <v-card class="mb-6">
            <v-card-title>Contactos</v-card-title>
            <ContactsList
            :parent-element="'contacts'"
            />
        </v-card>
    </div>
</template>

<script>
import ContactsList from '@/components/Contacts/ContactsList.vue'

export default {
    components: {
        ContactsList
    }
}
</script>
